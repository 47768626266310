import type { ComponentType } from "react"
import { useEffect, useState, useRef, forwardRef } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^0.3.0"

export function withMenuId(Component): ComponentType {
    return (props) => {
        let classes = props.className || ""
        classes += " close-menu-icon"
        return (
            <Component
                {...props}
                id="close-menu-icon"
                className={classes}
                data-id="close-menu-icon"
            />
        )
    }
}

export function withHeroImageId(Component): ComponentType {
    return (props) => {
        return <Component {...props} id="hero-image" />
    }
}

export function withCookieProBtn(Component): ComponentType {
    const addBtn = () => {
        const cookieProButton = document.createElement("button")
        cookieProButton.setAttribute("class", "ot-sdk-show-settings d-none")
        document.body.appendChild(cookieProButton)
    }

    const addScript = () => {
        const cookieProScript = document.createElement("script")
        cookieProScript.setAttribute(
            "src",
            "https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        )
        cookieProScript.setAttribute("type", "text/javascript")
        cookieProScript.setAttribute("charset", "UTF-8")
        cookieProScript.setAttribute(
            "data-domain-script",
            "2d50dacc-42f8-4449-bfe2-bd85fbbc160a"
        )
        document.head.appendChild(cookieProScript)
        window.OptanonWrapper = function () {}
    }

    const handleClick = () => {
        const cookieProButton = document.querySelector(".ot-sdk-show-settings")
        const cookieProScript = document.querySelector(
            "[data-domain-script='2d50dacc-42f8-4449-bfe2-bd85fbbc160a']"
        )
        cookieProButton ? cookieProButton?.click() : addBtn()
        // !cookieProScript && addScript()
    }
    return (props) => {
        return <Component {...props} id="cookieProBtn" onClick={handleClick} />
    }
}

export function withGetStartedButtonId(Component): ComponentType {
    return (props) => {
        const handleClick = () => {
            const flagVariant =
                window?.posthog?.getFeatureFlag("survey-experiment")

            console.log("handling button click")
            window?.posthog.capture("Clicked Get Started")

            if (flagVariant && flagVariant === "test") {
                window.location.assign("https://go.divorce.com/s/start")
                return
            }

            props.onTap?.() || props.onClick?.()
        }

        return (
            <Component
                data-class="lead-form-get-started"
                {...props}
                onTap={handleClick}
            />
        )
    }
}

// code override to
export function withGetStartedButtonExperiment(Component): ComponentType {
    return (props) => {
        const [variant, setVariant] = useState(null)

        const handleClick = () => {
            window?.posthog.capture("Clicked Get Started")
            window.location.assign("https://go.divorce.com/s/start")
        }

        useEffect(() => {
            const flagVariant =
                window?.posthog?.getFeatureFlag("survey-experiment")
            if (!flagVariant) return
            console.log("get started button flag variant", flagVariant)
            setVariant(flagVariant)
        }, [])

        // console.log("rendered flag variant", variant)

        return <Component onTap={handleClick} onClick={handleClick} />
    }
}

export function withSurveyExperimentLeadFormButton(Component): ComponentType {
    return forwardRef((props, ref) => {
        const flagVal = window?.posthog?.getFeatureFlag?.("survey-experiment")
        const [data, setData] = useState({
            flagVariant: flagVal,
            flagsLoaded: !!flagVal,
        })

        useEffect(() => {
            if (!window?.posthog) return
            if (data.flagsLoaded) {
                // console.log("flags already loaded, returning")
                return
            }

            window?.posthog?.onFeatureFlags(() => {
                const variant =
                    window?.posthog?.getFeatureFlag("survey-experiment")
                // console.log("setting flagVariant in store", variant)
                setData({ flagVariant: variant, flagsLoaded: true })
                // console.log("[hideLeadForm] POSTHOG FLAGS LOADED")
            })
        }, [])

        // console.log("[hideLeadForm] data.flagVariant", data.flagVariant)
        // console.log("[hideLeadForm] props", props)
        let cstyle = {}
        if (props["aria-label"] === "get-started") {
            cstyle = {
                display: data.flagVariant === "control" ? "none" : "block",
                height: "auto",
                opacity: "1",
            }
        } else if (props["aria-label"] === "lead-form") {
            cstyle = {
                display: data.flagVariant === "control" ? "block" : "none",
            }
        }

        return (
            <Component
                ref={ref}
                {...props}
                style={{
                    ...props?.style,
                    ...cstyle,
                }}
            />
        )
    })
}

// selectively hides the child lead form if we need to show the get started button instead
export function withSurveyLeadFormWrapper(Component): ComponentType {
    return forwardRef((props, ref) => {
        // after the first page load, this should immediately available
        const flagVal = window?.posthog?.getFeatureFlag?.("survey-experiment")
        const [data, setData] = useState({
            flagVariant: flagVal,
            flagsLoaded: !!flagVal,
        })

        // console.log("state", data)
        // console.log("props", props)

        const handleClick = () => {
            console.log("handle click")
        }

        const hideGetStarted = () => {
            if (!window?.document) return

            var getStartedBtn = window?.document?.querySelector(
                "[aria-label='survey-experiment-wrapper'] [aria-label='get-started']"
            )

            if (getStartedBtn) {
                setTimeout(() => {
                    console.log("hiding get started btn")
                    getStartedBtn.style.display = "none"
                }, 1)
            }
        }

        useEffect(() => {
            if (!window?.posthog) return
            if (data.flagsLoaded) {
                console.log("flags already loaded, returning")
                return
            }

            // console.log("running on featue flag loaded effect hook")
            // console.log("store", store)
            // console.log("flagsLoaded ref", data.flagsLoaded)

            window?.posthog?.onFeatureFlags(() => {
                const variant =
                    window?.posthog?.getFeatureFlag("survey-experiment")
                console.log("setting flagVariant in store", variant)
                // setStore({ flagsLoaded: true, flagVariant: variant })
                // setFlagsLoaded(true)
                // setFlagVariant(variant)
                setData({ flagVariant: variant, flagsLoaded: true })
                // flagsLoaded.current = true
                console.log("POSTHOG FLAGS LOADED")
            })
        }, [])

        // this effect block will get run once posthog flags are loaded.
        // useEffect(() => {
        //     console.log(
        //         `use effect update. flagsLoaded=${data.flagsLoaded}; flagVariant=${data.flagVariant}`
        //     )
        //     if (!window?.document) return
        //     if (!data.flagsLoaded) return
        //     // if (flagVariant === null) return

        //     // console.log("flag variant", flagVariant)

        //     // setVariant(flagVariant)

        //     if (data.flagVariant === "test") {
        //         var leadForm = window.document.querySelector(
        //             "[aria-label='survey-experiment-wrapper'] [aria-label='lead-form']"
        //         )
        //         var getStartedBtn = window.document.querySelector(
        //             "[aria-label='survey-experiment-wrapper'] [aria-label='get-started']"
        //         )

        //         // if we're in the test variant, show the button after the next render cycle
        //         if (leadForm && getStartedBtn) {
        //             // setTimeout(() => {
        //             //     console.log("show get started btn")
        //             //     getStartedBtn.style.opacity = "1.0"
        //             //     getStartedBtn.style.display = "block"
        //             //     getStartedBtn.style.height = "auto"
        //             //     // leadForm.style.display = "none"
        //             // }, 1)
        //         }
        //     } else if (data.flagVariant === "control") {
        //         console.log("[control] hiding get started")
        //         // hideGetStarted()
        //     }
        //     return null
        // }, [data.flagVariant])

        // console.log("variant", data.flagVariant)
        // console.log("flags loaded", data.flagsLoaded)

        // short-circuit for framer preview or posthog not avail
        // if (!window?.posthog) {
        //     console.log("hiding get started bc no posthog")
        //     hideGetStarted()
        //     return <Component {...props} />
        // }

        // if flags aren't loaded, hide the wrapper
        const cstyle = {
            ...props?.style,
            opacity: data.flagsLoaded ? "1" : "0",
            // this transition is delayed by 100ms to give the above setTimeout enough time to run to hide the form
            transition: "opacity 0.3s ease-in-out 200ms",
        }

        // console.log("cstyle", cstyle)

        return <Component ref={ref} {...props} style={cstyle} />
    })
}

export function withGetStartedClickTrack(Component): ComponentType {
    return (props) => {
        const handleClick = () => {
            console.log("handling button click")
            window?.posthog.capture("Clicked Lead Form Get Started")
            props.onTap?.() || props.onClick?.()
        }

        return (
            <Component {...props} onClick={handleClick} onTap={handleClick} />
        )
    }
}
